<template>
  <component :is="rootProps.is" v-bind="{ ...rootProps }">
    <q-btn
      :disable="disable"
      label="Cancel"
      :to="prevPage ? { name: prevPage } : undefined"
      @click="goBack"
    />
    <q-btn
      type="submit"
      :disable="disable"
      color="primary"
      :label="isEditingForm ? 'Update' : 'Create'"
      @click="$emit('submit')"
    />
  </component>
</template>

<script>
import { Fragment } from 'vue-fragment';

export default {
  props: {
    disable: {
      default: () => false,
      type: Boolean,
    },
    prevPage: {
      type: String,
      default: () => undefined,
    },
    isEditingForm: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    rootProps: ({ $q }) =>
      $q.screen.lt.md
        ? {
            is: 'q-btn-group',
            spread: true,
          }
        : {
            is: Fragment,
          },
  },
  methods: {
    goBack() {
      if (this.prevPage) return;
      this.$router.back();
    },
  },
};
</script>
