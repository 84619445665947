<template>
  <q-page padding class="fit column">
    <d-loader v-if="loading" />
    <q-card class="col column">
      <q-form @submit="submit" class="col column">
        <q-card-section class="col-2 card-top-section">
          <d-back-btn
            v-if="$q.screen.gt.sm"
            :to="prevPage ? { name: prevPage } : undefined"
            class="q-mr-sm"
            @click="goBack"
          />
          <div v-if="cardTitle" class="text-h5">
            {{ cardTitle }}
          </div>
        </q-card-section>
        <slot v-bind="{ loading, data, find, remove, submit }" />
        <q-card-actions class="col-2" align="right">
          <slot
            name="actions"
            v-bind="{ loading, data, find, remove, submit }"
          />
        </q-card-actions>
      </q-form>
    </q-card>
  </q-page>
</template>

<script>
import DBackBtn from '@/shared/ui/back-btn';
import DLoader from '@/shared/ui/loader';
import { dialogs, notify } from '@/shared/utils';

export default {
  name: 'EditCard',
  components: { DLoader, DBackBtn },
  props: {
    prevPage: {
      type: String,
      default: () => null,
    },
    cardTitle: {
      type: String,
      default: () => null,
    },
    entity: {
      type: Object,
      default: () => null,
    },
    id: {
      type: [Number, String],
      default: () => null,
    },
    fetchFunction: {
      type: Function,
      required: false,
    },
    removeFunction: {
      type: Function,
      required: false,
    },
    submitFunction: {
      type: Function,
      required: false,
    },
    removeConfirmTitle: {
      type: String,
      default: () => 'Are you sure you want to delete the entity?',
    },
    removeSuccessTitle: {
      type: String,
      default: () => 'Success',
    },
    submitSuccessTitle: {
      type: String,
      default: () => 'Success',
    },
    redirectAfterCreation: {
      type: String,
      default: () => null,
    },
  },

  data: (vm) => ({
    data: vm.entity,
    loading: false,
  }),

  watch: {
    data: {
      handler: function (val) {
        this.$emit('update:entity', val);
      },
      deep: true,
      immediate: true,
    },
  },

  created() {
    if (this.id) this.find();
  },

  methods: {
    setLoading(value) {
      this.loading = value;
    },

    goBack() {
      if (this.prevPage) return;
      this.$router.back();
    },

    async find() {
      if (!this.fetchFunction) {
        throw Error("Property 'fetchFunction' is not provided!");
      }
      try {
        this.loading = true;
        const { data } = await this.fetchFunction(this.id);
        this.$set(this, 'data', data);
      } catch (e) {
        notify.error(e.message);
        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    async remove() {
      if (!this.removeFunction) {
        throw Error("Property 'removeFunction' is not provided!");
      }
      dialogs
        .confirmDialog({
          message: this.removeConfirmTitle,
        })
        .onOk(async () => {
          try {
            this.loading = true;
            await this.removeFunction(this.id);
            await this.$router.push({
              name: this.prevPage,
            });
            notify.success(this.removeSuccessTitle);
          } catch (e) {
            notify.error(e.message);
            console.error(e);
          } finally {
            this.loading = false;
          }
        });
    },

    async submit() {
      if (typeof this.submitFunction !== 'function') return;

      try {
        this.loading = true;
        const { data } = await this.submitFunction(this.data);
        notify.success(this.submitSuccessTitle);
        if (!this.id && this.redirectAfterCreation) {
          await this.$router.push({
            name: this.redirectAfterCreation,
            params: { id: data.id },
          });
        }
      } catch (e) {
        console.error(e);
        notify.error(e.message);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.card-top-section {
  display: flex;
  flex-direction: row;
  background-color: var(--q-color-primary) !important;
  color: white;
  align-items: center;
  border-radius: 4px 4px 0 0;
}
</style>
