export default (condition) => ({
  computed: {
    isEditingForm: ({ $route }) => {
      if (condition !== undefined) {
        return condition;
      } else {
        return !!$route?.params?.id;
      }
    },
  },
});
