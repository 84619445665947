var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('d-edit-card',{key:_vm.$route.params.id,attrs:{"id":_vm.$route.params.id,"fetch-function":_vm.merchantController.getMerchantById,"entity":_vm.form,"prev-page":"MerchantsList","card-title":("Merchant " + (_vm.isEditingForm ? 'updating' : 'creation')),"remove-confirm-title":"Are you sure you want to delete this merchant?","remove-success-title":"Merchant successfully removed","remove-function":_vm.merchantController.removeMerchant,"submit-function":_vm.submitForm,"redirect-after-creation":"MerchantEdit","submit-success-title":_vm.isEditingForm
      ? 'Changes successfully saved!'
      : 'Merchant successfully created!'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var data = ref.data;
return [_c('q-card-section',{staticClass:"col"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-md-6 q-col-gutter-md"},[_c('q-input',{attrs:{"outlined":"","label":"Name","hint":"Required field*","rules":[_vm.rules.notNullField]},model:{value:(data.name),callback:function ($$v) {_vm.$set(data, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"data.name"}}),_c('q-input',{attrs:{"outlined":"","label":"Secret","hint":"Required field*","rules":[_vm.rules.notNullField]},model:{value:(data.secret),callback:function ($$v) {_vm.$set(data, "secret", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"data.secret"}}),_c('q-input',{attrs:{"outlined":"","label":"Payout callback","hint":"Required field*","rules":[_vm.rules.notNullField, _vm.rules.checkRegExp]},model:{value:(data.payoutCallback),callback:function ($$v) {_vm.$set(data, "payoutCallback", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"data.payoutCallback"}}),_c('q-input',{attrs:{"outlined":"","label":"Refill callback","hint":"Required field*","rules":[_vm.rules.notNullField, _vm.rules.checkRegExp]},model:{value:(data.refillCallback),callback:function ($$v) {_vm.$set(data, "refillCallback", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"data.refillCallback"}})],1)])])]}},{key:"actions",fn:function(ref){
      var loading = ref.loading;
      var remove = ref.remove;
return [_c('d-edit-form-actions',{attrs:{"is-editing-form":_vm.isEditingForm,"prev-page":"MerchantsList"},on:{"remove":remove}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }