<template>
  <q-btn @click="onClick" flat fab-mini icon="mdi-arrow-left-thick" :to="to" />
</template>

<script>
export default {
  name: 'DBackBtn',
  props: {
    to: {
      type: [Object, String],
      default: () => undefined,
    },
  },
  methods: {
    onClick(evt) {
      this.$emit('click', evt);
    },
  },
};
</script>
