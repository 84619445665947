<template>
  <div class="loader-body">
    <slot>
      <q-spinner-cube :color="spinnerColor" size="5.5em" />
    </slot>
  </div>
</template>

<script>
export default {
  name: 'DLoader',
  props: {
    spinnerColor: {
      type: String,
      default: () => 'primary',
    },
  },
};
</script>

<style scoped lang="scss">
.loader-body {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin: 0 !important;
}
</style>
