<template>
  <d-edit-card
    :key="$route.params.id"
    :id="$route.params.id"
    :fetch-function="merchantController.getMerchantById"
    :entity="form"
    prev-page="MerchantsList"
    :card-title="`Merchant ${isEditingForm ? 'updating' : 'creation'}`"
    remove-confirm-title="Are you sure you want to delete this merchant?"
    remove-success-title="Merchant successfully removed"
    :remove-function="merchantController.removeMerchant"
    :submit-function="submitForm"
    redirect-after-creation="MerchantEdit"
    :submit-success-title="
      isEditingForm
        ? 'Changes successfully saved!'
        : 'Merchant successfully created!'
    "
  >
    <template #default="{ data }">
      <q-card-section class="col">
        <div class="row">
          <div class="col-xs-12 col-md-6 q-col-gutter-md">
            <q-input
              v-model.trim="data.name"
              outlined
              label="Name"
              hint="Required field*"
              :rules="[rules.notNullField]"
            />
            <q-input
              v-model.trim="data.secret"
              outlined
              label="Secret"
              hint="Required field*"
              :rules="[rules.notNullField]"
            />
            <q-input
              v-model.trim="data.payoutCallback"
              outlined
              label="Payout callback"
              hint="Required field*"
              :rules="[rules.notNullField, rules.checkRegExp]"
            />
            <q-input
              v-model.trim="data.refillCallback"
              outlined
              label="Refill callback"
              hint="Required field*"
              :rules="[rules.notNullField, rules.checkRegExp]"
            />
          </div>
        </div>
      </q-card-section>
    </template>
    <template #actions="{ loading, remove }">
      <d-edit-form-actions
        :is-editing-form="isEditingForm"
        prev-page="MerchantsList"
        @remove="remove"
      />
    </template>
  </d-edit-card>
</template>

<script>
import { DEditFormActions } from '@/features/edit-form-actions';
import DEditCard from '@/layouts/edit-card';
import { merchantController } from '@/shared/api';
import withIsEditingForm from '@/shared/mixins/withIsEditingForm';

export default {
  components: { DEditCard, DEditFormActions },
  mixins: [withIsEditingForm()],
  data: (vm) => ({
    regExp:
      /\b(?:(?:https?|ftp):\/\/|www\.)[-a-z0-9+&@#\\/%?=~_|!:,.;]*[-a-z0-9+&@#\\/%=~_|]/i,
    rules: {
      notNullField: (val) => (val && val.length > 0) || 'Please type value',
      checkRegExp: (val) => vm.regExp.test(val) || 'Invalid URL',
    },
    form: {
      name: null,
      secret: null,
      refillCallback: null,
      payoutCallback: null,
    },
    merchantController,
  }),

  methods: {
    async submitForm(payload) {
      if (this.isEditingForm) {
        return await merchantController.updateMerchant({
          ...payload,
        });
      } else {
        return await merchantController.createMerchant({
          ...payload,
        });
      }
    },
  },
};
</script>
